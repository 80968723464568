import axios, { AxiosResponse } from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { Action, ActionCreator, Dispatch } from 'redux';
import { setFieldErrors } from '../redux/actions/fieldErrors';
import { addFormError } from '../redux/actions/fieldErrors';
import { setRedirectUrl } from '../redux/actions/redirects';
import { APIError } from '../types/APIError';
import { clearJwtAndRefreshToken } from './jwt';

const getConfig = () => ({
    headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
});

let API_URL = '';
export const initApi = (apiUrl: string) => {
    API_URL = apiUrl;
};
const get = async <TResponseBody>(url: string): Promise<AxiosResponse<TResponseBody>> => {
    await handleRefreshTokens();
    return axios.get<TResponseBody>(`${API_URL}/${url}`, getConfig());
};
const post = async <TRequestBody, TResponseBody>(url: string, data: TRequestBody) => {
    await handleRefreshTokens();
    return axios.post<TResponseBody>(`${API_URL}/${url}`, data, getConfig());
};
const put = async <TRequestBody, TResponseBody>(url: string, data: TRequestBody) => {
    await handleRefreshTokens();
    return axios.put<TResponseBody>(`${API_URL}/${url}`, data, getConfig());
};
const patch = async <TRequestBody, TResponseBody>(url: string, data: TRequestBody) => {
    await handleRefreshTokens();
    return axios.patch<TResponseBody>(`${API_URL}/${url}`, data, getConfig());
};
const del = async <TResponseBody>(url: string) => {
    await handleRefreshTokens();
    return axios.delete<TResponseBody>(`${API_URL}/${url}`, getConfig());
};

const logError = async (body: ErrorLogRequest) => {
    // todo error request body
    return axios.post(`${API_URL}/error`, body, getConfig());
};

export const api = {
    get,
    post,
    put,
    patch,
    delete: del,
    logError,
};

export const handleApiErrors = <A extends Action>(
    dispatch: Dispatch,
    failureAction: ActionCreator<A>,
    err: APIError,
) => {
    const { response, message } = err;

    if (response && response.status === 400) {
        typeof response.data === 'string'
            ? dispatch(addFormError(response.data))
            : dispatch(setFieldErrors(response.data.errors));

        return dispatch(failureAction(null));
    }
    if (response && response.status === 401) {
        dispatch(failureAction('Unauthorized'));
        clearJwtAndRefreshToken();
        return dispatch(setRedirectUrl('/auth/login'));
    }

    return dispatch(failureAction(message));
};

const handleRefreshTokens = async () => {
    try {
        const jwt = localStorage.getItem('jwt');
        const refreshToken = localStorage.getItem('refreshToken');

        if (!refreshToken || !jwt) {
            return;
        }
        const decoded = jwtDecode<JwtPayload>(jwt);
        const now = new Date().getTime() / 1000;
        const { exp = 0 } = decoded;
        // if token expires within 5 minutes, refresh
        const minutesUntilExpiration = (exp - now) / 60;
        if (minutesUntilExpiration <= 2) {
            const { data } = await axios.post<RefreshTokenRequest, AxiosResponse<LoginResponse>>(
                `${API_URL}/auth/refresh-token`,
                {
                    refreshToken,
                    expiredToken: jwt,
                },
            );
            localStorage.setItem('jwt', data.token);
            localStorage.setItem('refreshToken', data.refreshToken);
        }
    } catch {
        // ignore err, dealt with in handleApiErrors
    }
};

interface RefreshTokenRequest {
    refreshToken: string;
    expiredToken: string;
}

export interface LoginResponse {
    token: string;
    refreshToken: string;
}

export interface ErrorLogRequest {
    message?: string;
    stackTrace?: string;
    appVersion: string;
    device: string;
    deviceOS: string;
    deviceRAM: number | null;
}
